import './App.css';
import React, { useState, useEffect } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Modal from 'react-modal';
import SocialMedia from './components/SocialMedia';

Modal.setAppElement('#root');

const App = () => {
  const [players, setPlayers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [guessCount, setGuessCount] = useState(0);
  const [isGuessModalOpen, setIsGuessModalOpen] = useState(false);
  const [isOutOfGuessesModalOpen, setIsOutOfGuessesModalOpen] = useState(false);
  const [targetPlayer, setTargetPlayer] = useState(null);
  const [sport, setSport] = useState('Football');
  const [overlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = () => {
    setOverlayVisible(!overlayVisible);
  };

  useEffect(() => {
    const storedSport = localStorage.getItem('sport');
    if (storedSport) {
      setSport(storedSport);
    }
    fetchPlayers(storedSport || 'Football');
  }, []);
  const fetchPlayers = async (selectedSport) => {
    try {
      const response = await fetch(`https://gaa-guess-who-backend.onrender.com/api/${selectedSport}`);
      // const response = await fetch(`http://localhost:8080/api/${selectedSport}`);
      const data = await response.json();
      setPlayers(data);
      const randomPlayer = data[Math.floor(Math.random() * data.length)];
      setTargetPlayer(randomPlayer);
      localStorage.setItem(`targetPlayer_${selectedSport}`, JSON.stringify(randomPlayer));
      localStorage.setItem('guessCount', '0');
      setGuessCount(0);
      setSelectedPlayers([]);
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  };
  const handleSportSwitch = (selectedSport) => {
    setSport(selectedSport);
    localStorage.setItem('sport', selectedSport);
    fetchPlayers(selectedSport);
    window.location.reload()
  };

  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filteredPlayers = players.filter(player =>
      player.name.toLowerCase().includes(value)
    ).slice(0, 4); // Limiting the suggestions to 4 names
    setSuggestions(filteredPlayers);
  };

  const handlePlayerSelect = (player) => {
    const updatedSelectedPlayers = [...selectedPlayers, player];
    setSelectedPlayers(updatedSelectedPlayers);
    localStorage.setItem('selectedPlayers', JSON.stringify(updatedSelectedPlayers));
    setSearchTerm('');
    setSuggestions([]);
    setGuessCount(guessCount + 1);
    localStorage.setItem('guessCount', guessCount + 1);
    const updatedPlayers = players.filter(p => p.id !== player.id);
    setPlayers(updatedPlayers);
    if (isGuessCorrect(player)) {
      setIsGuessModalOpen(true);
    } else if (guessCount + 1 >= 10) {
      setIsOutOfGuessesModalOpen(true);
    }
  };

  const getAttributeClass = (attribute, correctAttribute, selectedPlayer, targetPlayer) => {
    let baseClass = 'attribute-square';
    if (attribute === correctAttribute) {
      baseClass += ' correct';
    } else if (selectedPlayer.province === targetPlayer.province && selectedPlayer.county !== targetPlayer.county && attribute === selectedPlayer.county) {
      baseClass += ' near-miss';
    } else if (typeof attribute === 'number' && typeof correctAttribute === 'number') {
      if (Math.abs(attribute - correctAttribute) === 1) {
        baseClass += ' light-green';
      } else if (Math.abs(attribute - correctAttribute) <= 2) {
        baseClass += ' yellow';
      }
      if (attribute > correctAttribute) {
        baseClass += ' lower-arrow';
      } else if (attribute < correctAttribute) {
        baseClass += ' higher-arrow';
      }
    } else {
      baseClass += ' incorrect';
    }
    return baseClass;
  };

  const isGuessCorrect = (selectedPlayer) => {
    if (!selectedPlayer || !targetPlayer) return false;
    return (
      selectedPlayer.name === targetPlayer.name &&
      selectedPlayer.position === targetPlayer.position &&
      selectedPlayer.county === targetPlayer.county &&
      selectedPlayer.debutYear === targetPlayer.debutYear &&
      selectedPlayer.allStars === targetPlayer.allStars &&
      selectedPlayer.leagueWins === targetPlayer.leagueWins &&
      selectedPlayer.provincialChampionshipWins === targetPlayer.provincialChampionshipWins &&
      selectedPlayer.allIrelandWins === targetPlayer.allIrelandWins
    );
  };

  const revealTargetPlayer = () => {
    setIsOutOfGuessesModalOpen(true);
  };

  const closeOutOfGuessesModal = () => {
    setIsOutOfGuessesModalOpen(false);
    const newTargetPlayer = players[Math.floor(Math.random() * players.length)];
    setTargetPlayer(newTargetPlayer);
    localStorage.setItem('targetPlayer', JSON.stringify(newTargetPlayer));
    setSelectedPlayers([]);
    localStorage.removeItem('selectedPlayers');
    setGuessCount(0);
    localStorage.setItem('guessCount', 0);
    window.location.reload()
  };

  const shareResult = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'My Guessing Game Result',
          text: `I guessed the player ${targetPlayer.name} correctly! #GAAGuessWho`,
          url: window.location.href
        });
      } else {
        throw new Error('Web Share API not supported');
      }
    } catch (error) {
      console.error('Error sharing result:', error);
    }
  };

  return (
    <div className="app">
      <div className="navbar">
        <div className="dropdown">
          <button className="dropbtn">
            {sport}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
              style={{ marginLeft: '8px' }}
            >
              <path d="M7.247 11.14l-4.796-5.481C1.348 4.708 1.925 4 2.773 4h10.454c.848 0 1.425.708.322 1.659l-4.796 5.48a1 1 0 0 1-1.606 0z" />
            </svg>
          </button>
          <div className="dropdown-content">
            <button className={sport === 'Football' ? 'active' : ''} onClick={() => handleSportSwitch('Football')}>Football</button>
            <button className={sport === 'Hurling' ? 'active' : ''} onClick={() => handleSportSwitch('Hurling')}>Hurling</button>
            <button className={sport === 'LGFA' ? 'active' : ''} onClick={() => handleSportSwitch('LGFA')}>LGFA</button>
            {/* <button className={sport === 'Camogie' ? 'active' : ''} onClick={() => handleSportSwitch('Camogie')}>Camogie</button> */}
          </div>
        </div>
    
        <div onClick={toggleOverlay}>
          <QuestionMarkIcon className="info-icon" />
        </div>
        {overlayVisible && (
          <div className="info-overlay" onClick={toggleOverlay}>
            <img 
              src="/Rules2.png" 
              alt="Info Icon with game rules" 
            />
          </div>
        )}
      </div>
      <div className='body-container'>
      <div className="header">
        <div className="title1">
          <h1>GAA Guess Who</h1>
        </div>
        <div className="guesses-remaining">
          <span className="label">Guesses: </span>
          <span className="count">{10 - guessCount}</span>
        </div>
      </div>
      <div className="search-container">
        <div className="give-up-section">
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search player..."
            className="search-input"
          />
          <button onClick={revealTargetPlayer} className="give-up">Give Up</button>
        </div>
        {suggestions.length > 0 && (
          <ul className="suggestion-list">
            {suggestions.map(player => (
              <li key={player.id} onClick={() => handlePlayerSelect(player)}>
                {player.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="selected-players">
        {selectedPlayers.slice(0).reverse().map((selectedPlayer, index) => (
          <div key={index} className="player-details">
            <div className="player-image-container">
              <img src={selectedPlayer.imageURL} alt={selectedPlayer.name} className="player-image" />
            </div>
            <div class="player-name">
              <h2>
                {selectedPlayer.name}
              </h2>
            </div>
            <div className="attribute-row">
              <div className="attribute-item">
                <span className="attribute-label">County:</span>
                <div className={getAttributeClass(selectedPlayer.county, targetPlayer.county, selectedPlayer, targetPlayer)}>{selectedPlayer.county}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Position:</span>
                <div className={getAttributeClass(selectedPlayer.position, targetPlayer.position, selectedPlayer, targetPlayer)}>{selectedPlayer.position}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Debut:</span>
                <div className={getAttributeClass(selectedPlayer.debutYear, targetPlayer.debutYear, selectedPlayer, targetPlayer)}>{selectedPlayer.debutYear}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">All Stars:</span>
                <div className={getAttributeClass(selectedPlayer.allStars, targetPlayer.allStars, selectedPlayer, targetPlayer)}>{selectedPlayer.allStars}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">League Wins:</span>
                <div className={getAttributeClass(selectedPlayer.leagueWins, targetPlayer.leagueWins, selectedPlayer, targetPlayer)}>{selectedPlayer.leagueWins}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Provincial Wins:</span>
                <div className={getAttributeClass(selectedPlayer.provincialChampionshipWins, targetPlayer.provincialChampionshipWins, selectedPlayer, targetPlayer)}>{selectedPlayer.provincialChampionshipWins}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">All Ireland Wins:</span>
                <div className={getAttributeClass(selectedPlayer.allIrelandWins, targetPlayer.allIrelandWins, selectedPlayer, targetPlayer)}>{selectedPlayer.allIrelandWins}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="credits">
        <div>Records collected from Wikipedia</div>
      </div> */}
      <Modal
        isOpen={isGuessModalOpen}
        onRequestClose={closeOutOfGuessesModal}
        contentLabel="Guess Correct Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h3>Congrats! You guessed the correct player!</h3>
        {targetPlayer && (
          <div className="player-details">
            <div className="player-image-container">
              <img src={targetPlayer.imageURL} alt={targetPlayer.name} className="player-image" />
            </div>
            <h2>{targetPlayer.name}</h2>
            <div className="attribute-row">
              <div className="attribute-item">
                <span className="attribute-label">County:</span>
                <div>{targetPlayer.county}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Position:</span>
                <div>{targetPlayer.position}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Debut Year:</span>
                <div>{targetPlayer.debutYear}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">All Stars:</span>
                <div>{targetPlayer.allStars}</div>
              </div>
            </div>
            <div className="attribute-row">
              <div className="attribute-item">
                <span className="attribute-label">League Wins:</span>
                <div>{targetPlayer.leagueWins}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Provincial Wins:</span>
                <div>{targetPlayer.provincialChampionshipWins}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">All Ireland Wins:</span>
                <div>{targetPlayer.allIrelandWins}</div>
              </div>
            </div>
          </div>
        )}
        <div className="button-container">
          <button className="play-again" onClick={closeOutOfGuessesModal}>Play Again</button>
          <button className="share-button" onClick={shareResult}>Share</button>
        </div>
      </Modal>
      <Modal
        isOpen={isOutOfGuessesModalOpen}
        onRequestClose={closeOutOfGuessesModal}
        contentLabel="Out of Guesses Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h3>Out of guesses! The correct player was:</h3>
        {targetPlayer && (
          <div className="player-details">
            <div className="player-image-container">
              <img src={targetPlayer.imageURL} alt={targetPlayer.name} className="player-image" />
            </div>
            <h2>{targetPlayer.name}</h2>
            <div className="attribute-row">
              <div className="attribute-item">
                <span className="attribute-label">County:</span>
                <div>{targetPlayer.county}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Position:</span>
                <div>{targetPlayer.position}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Debut Year:</span>
                <div>{targetPlayer.debutYear}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">All Stars:</span>
                <div>{targetPlayer.allStars}</div>
              </div>
            </div>
            <div className="attribute-row">
              <div className="attribute-item">
                <span className="attribute-label">League Wins:</span>
                <div>{targetPlayer.leagueWins}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">Provincial Wins:</span>
                <div>{targetPlayer.provincialChampionshipWins}</div>
              </div>
              <div className="attribute-item">
                <span className="attribute-label">All Ireland Wins:</span>
                <div>{targetPlayer.allIrelandWins}</div>
              </div>
            </div>
          </div>
        )}
        <div class="play-again-container">
          <button class="play-again" onClick={closeOutOfGuessesModal}>Play Again</button>
        </div>
      </Modal>
        <div>
          <SocialMedia className="social-media"/>
        </div>
      </div>
    </div>
  );
};

export default App;