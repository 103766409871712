import React from 'react';
import '../App.css'; // Ensure this path is correct

const SocialMedia = () => {

  return (
    <div className="social-media-container">
      <h4>Powered by: </h4>
      <a href="https://x.com/TacticsAI" target="_blank" rel="noopener noreferrer">
        <img 
          src="https://pbs.twimg.com/profile_images/1728589817590472704/Vz6xUhS9_400x400.jpg" 
          alt="Follow me on X.com" 
          className="gaa-ai-logo" 
        />
      </a>     
    </div>
  );
};

export default SocialMedia;
